import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import logoCPCS from './Loader_GIF/Logo_CPCS.png';
import logoLumitics from './Loader_GIF/Logo_Lumitics.png';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)({
  textTransform: 'none',
  padding: '3px 30px 2px 30px',
  border: `3px solid`,
  borderColor: '#969D9E',
  borderRadius: '24px',
  fontSize: 20,
  fontWeight: 'bold',
  color: '#73DEDE',
  '&:hover': {
    color: '#FFFFFF',
    backgroundColor: '#EB7D6E',
    border: `3px solid`,
    borderColor: '#EB7D6E',
  },
});

const HeaderBar = (props) => {
  const { onClickLogout } = props;

  return (
    <Grid item width="100%">
      <AppBar
        position="static"
        sx={{
          background: 'linear-gradient(90deg, #102547, #1D4486)',
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
        <Toolbar>
          <Box sx={{ paddingLeft: 2, paddingRight: 5 }}>
            <img height="56px" src={logoLumitics} alt="Lumitics" />
          </Box>
          <Divider flexItem orientation="vertical" sx={{ borderRight: 3, color: '#969D9E' }} />
          <Grid container direction="row" sx={{ paddingLeft: 3 }}>
            <img height="50px" src={logoCPCS} alt="CPCS" />
            <Grid item sx={{ paddingLeft: 3 }}>
              <Typography
                sx={{
                  borderBottom: 2.5,
                  borderColor: '#63C6CA',
                  fontSize: '26px',
                  position: 'relative',
                  top: '7.5px',
                  color: '#CCCCCC',
                }}
              >
                INVENTORY TRACKER
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" width="30%" justifyContent="flex-end" sx={{ paddingRight: 2 }}>
            <StyledButton variant="outlined" onClick={onClickLogout}>
              Log out
            </StyledButton>
          </Stack>
        </Toolbar>
      </AppBar>
    </Grid>
  );
};

export default HeaderBar;
