import { useState } from 'react';
import './App.css';
import Login from './Login';
import Edit from './Edit';
import Home from './Home';
import Tag from './Tag';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import HeaderBar from './HeaderBar';
import { useAuth0 } from '@auth0/auth0-react';

const BasicLayoutForNonLoginPage = () => {
  const { logout } = useAuth0();

  const onClickLogout = () => {
    logout();
  };

  return (
    <>
      <HeaderBar onClickLogout={onClickLogout} />
      <Outlet />
    </>
  );
};

function App() {
  const [arrBatchDataWithAnalysis, setArrBatchDataWithAnalysis] = useState(null);
  const [arrFilteredAndSortedBatchDataWithAnalysis, setArrFilteredAndSortedBatchDataWithAnalysis] =
    useState(null);
  const [
    arrRecordWithAdditionalDataOfSelectedBatch,
    setArrRecordWithAdditionalDataOfSelectedBatch,
  ] = useState(null);
  const [arrFoodItem, setArrFoodItem] = useState(null);
  const [arrLocation, setArrLocation] = useState(null);

  const [arrSelectedOptionToBeFilteredBy, setArrSelectedOptionToBeFilteredBy] = useState([]);
  // Note: sortBy and filterBy uses the id (number) instead of the label (string) because there will be comparison done with this parameter
  // and number comparison is more efficient
  const [sortBy, setSortBy] = useState(3);
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterBy, setFilterBy] = useState(null);

  const initialiseUserDataAfterRefresh = (data, arrNewFilteredAndSortedBatchDataWithAnalysis) => {
    setArrBatchDataWithAnalysis(data.arrBatchDataWithAnalysis);
    setArrFilteredAndSortedBatchDataWithAnalysis(arrNewFilteredAndSortedBatchDataWithAnalysis);
    setArrFoodItem(data.arrFoodItem);
    setArrLocation(data.arrLocation);
  };

  const updateArrRecordWithAdditionalDataOfSelectedBatch = (
    arrRecordWithAdditionalDataOfNewSelectedBatch
  ) => {
    setArrRecordWithAdditionalDataOfSelectedBatch(arrRecordWithAdditionalDataOfNewSelectedBatch);
  };

  const updateFilterDetailsAfterConfirmation = (
    newFilterBy,
    arrNewFilteredAndSortedBatchDataWithAnalysis,
    arrNewSelectedOptionToBeFilteredBy
  ) => {
    setFilterBy(newFilterBy);
    setArrFilteredAndSortedBatchDataWithAnalysis(arrNewFilteredAndSortedBatchDataWithAnalysis);
    setArrSelectedOptionToBeFilteredBy(arrNewSelectedOptionToBeFilteredBy);
  };

  const updateSortDetails = (newSortOrder, newSortBy) => {
    setSortOrder(newSortOrder);
    setSortBy(newSortBy);
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<BasicLayoutForNonLoginPage />}>
          <Route
            index
            element={
              <Home
                arrBatchDataWithAnalysis={arrBatchDataWithAnalysis}
                arrFilteredAndSortedBatchDataWithAnalysis={
                  arrFilteredAndSortedBatchDataWithAnalysis
                }
                arrFoodItem={arrFoodItem}
                arrSelectedOptionToBeFilteredBy={arrSelectedOptionToBeFilteredBy}
                filterBy={filterBy}
                sortBy={sortBy}
                sortOrder={sortOrder}
                initialiseUserDataAfterRefresh={initialiseUserDataAfterRefresh}
                updateArrRecordWithAdditionalDataOfSelectedBatch={
                  updateArrRecordWithAdditionalDataOfSelectedBatch
                }
                updateFilterDetailsAfterConfirmation={updateFilterDetailsAfterConfirmation}
                updateSortDetails={updateSortDetails}
              />
            }
          />
          <Route
            path="/edit"
            element={
              arrRecordWithAdditionalDataOfSelectedBatch ? (
                <Edit
                  arrRecordWithAdditionalDataOfSelectedBatch={
                    arrRecordWithAdditionalDataOfSelectedBatch
                  }
                  arrFoodItem={arrFoodItem}
                  arrLocation={arrLocation}
                  updateArrRecordWithAdditionalDataOfSelectedBatch={
                    updateArrRecordWithAdditionalDataOfSelectedBatch
                  }
                />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route path="/tag" element={<Tag />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
