import { useState, useEffect, useCallback } from 'react';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';

/**
 * Find the array of food item name options and the array of product code options for selection in Edit page
 * @param {Object[]} arrFoodItem - Array of food items
 * @returns {Object}
 * @returns {Object[]} arrFoodItemNameOption - Array of food item name options for selection in Edit page
 * @returns {Object[]} arrProductCodeOption - Array of product code options for selection in Edit page
 */
const getSelectionOptionsForFoodItemNameAndProductCode = (arrFoodItem) => {
  const arrFoodItemNameOption = [];
  const arrProductCodeOption = [];
  arrFoodItem.forEach((foodItem) => {
    arrFoodItemNameOption.push(foodItem.name);
    arrProductCodeOption.push(foodItem.productCode);
  });
  return {
    arrFoodItemNameOption,
    arrProductCodeOption,
  };
};

/**
 * Add an error text for count, hasParameterChanged and hasCountChanged for 'into storage' records with 'TBC' count (untagged records). This error text is to notify the user
 * that the count has to be changed if they want to submit the changes.
 * @param {Object[]} arrRecordWithAdditionalData - Array of records
 * @returns {Object[]} arrRecordWithWithAdditionalDataErrorTextForUntagged - Array of record with additional data where untagged in record has an error text for count and a hasParameterChanged and hasCountChanged true
 */
const getArrRecordWithAdditionalDataWithErrorTextForUntagged = (arrRecordWithAdditionalData) => {
  const arrRecordWithAdditionalDataWithErrorTextForUntagged = arrRecordWithAdditionalData.map(
    (recordWithAdditionalData) => {
      const updatedRecordWithAdditionalData = recordWithAdditionalData;
      if (
        updatedRecordWithAdditionalData.count === 'TBC' &&
        updatedRecordWithAdditionalData.isGoingIntoStorage
      ) {
        updatedRecordWithAdditionalData.hasParameterChanged = true;
        updatedRecordWithAdditionalData.hasCountChanged = true;
        updatedRecordWithAdditionalData.errorTextForCount =
          'Please fill in a valid count. Count should be a number that is between 0 to 999.';
      }
      return updatedRecordWithAdditionalData;
    }
  );
  return arrRecordWithAdditionalDataWithErrorTextForUntagged;
};

const StyledAutocomplete = styled(Autocomplete)({
  // Clears the 'X' from Internet Explorer
  'input[type=search]::-ms-clear': {
    display: 'none',
    width: 0,
    height: 0,
  },
  'input[type=search]::-ms-reveal': {
    display: 'none',
    width: 0,
    height: 0,
  },
  // Clears the 'X' from Chrome
  'input[type=search]::-webkit-search-decoration': {
    display: 'none',
  },
  'input[type=search]::-webkit-search-cancel-button': {
    display: 'none',
  },
  'input[type=search]::-webkit-search-results-button': {
    display: 'none',
  },
  'input[type=search]::-webkit-search-results-decoration': {
    display: 'none',
  },
});

const StyledAutoFillButton = styled(Button)({
  fontSize: '16px',
  fontWeight: 'bold',
  color: '#63C6CA',
  border: '3px solid',
  borderRadius: '24px',
  borderColor: '#63C6CA',
  width: '320px',
  '&:hover': {
    color: '#1D4486',
    border: '3px solid',
    borderRadius: '24px',
    borderColor: '#1D4486',
  },
});

const StyledReturnButton = styled(Button)({
  textTransform: 'none',
  border: 'none',
  padding: '10px',
  color: '#1C406E',
  '&:hover': {
    textDecoration: 'underline',
    background: 'transparent',
  },
});

const StyledSubmitButton = styled(Button)({
  fontSize: '20px',
  fontWeight: 'bold',
  backgroundColor: '#63C6CA',
  borderRadius: '24px',
  width: '200px',
  '&:hover': {
    backgroundColor: '#1D4486',
    borderRadius: '24px',
  },
});

const Edit = (props) => {
  const {
    arrRecordWithAdditionalDataOfSelectedBatch,
    arrFoodItem,
    arrLocation,
    updateArrRecordWithAdditionalDataOfSelectedBatch,
  } = props;

  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [imageUrlToExpand, setImageUrlToExpand] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [arrRecordWithAdditionalData, setArrRecordWithAdditionalData] = useState(null);
  const [arrFoodItemNameOption, setArrFoodItemNameOption] = useState(null);
  const [arrProductCodeOption, setArrProductCodeOption] = useState(null);

  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const initialiseEditData = useCallback(() => {
    const selectionOptionsForFoodItemNameAndProductCode =
      getSelectionOptionsForFoodItemNameAndProductCode(arrFoodItem);
    const arrRecordWithAdditionalDataWithErrorForUntagged =
      getArrRecordWithAdditionalDataWithErrorTextForUntagged(
        arrRecordWithAdditionalDataOfSelectedBatch
      );
    setArrRecordWithAdditionalData(arrRecordWithAdditionalDataWithErrorForUntagged);
    setArrFoodItemNameOption(selectionOptionsForFoodItemNameAndProductCode.arrFoodItemNameOption);
    setArrProductCodeOption(selectionOptionsForFoodItemNameAndProductCode.arrProductCodeOption);
  }, [arrFoodItem, arrRecordWithAdditionalDataOfSelectedBatch]);

  useEffect(() => {
    if (isAuthenticated) {
      initialiseEditData();
      setShowLoader(false);
    } else if (isLoading) {
      setShowLoader(true);
    } else {
      navigate('/login', { replace: true });
    }
  }, [
    arrRecordWithAdditionalDataOfSelectedBatch,
    isAuthenticated,
    isLoading,
    initialiseEditData,
    navigate,
  ]);

  const onClickCardMedia = (imageUrl) => {
    setIsDialogOpened(true);
    setImageUrlToExpand(imageUrl);
  };

  const onCloseDialog = () => {
    closeDialog();
  };

  const onClickCloseDialog = () => {
    closeDialog();
  };

  const closeDialog = () => {
    setIsDialogOpened(false);
    setImageUrlToExpand('');
  };

  const onClickCancel = () => {
    redirectToHomeFromEdit();
  };

  const redirectToHomeFromEdit = () => {
    updateArrRecordWithAdditionalDataOfSelectedBatch(null);
    navigate('/', { replace: true });
  };

  const submitToUpdate = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      await axios.post(
        '/web-app/update',
        {
          arrRecordWithAdditionalData,
          isTagging: false,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      redirectToHomeFromEdit();
    } catch (error) {
      redirectToHomeFromEdit();
    }
  };

  /**
   * Before sending the whole array of records with additional data to the Backend for update, check if there is any input error. If there is, the
   * submission will be aborted and the page will be scrolled to the record card where the first input error is found. If there is no updated record,
   * the user will be redirected to the Home page.
   */
  const onClickSubmit = () => {
    let firstRecordIdWithInputError = 0;
    let hasUpdatedRecord = false;

    for (
      let recordWithAdditionalDataIndex = 0;
      recordWithAdditionalDataIndex < arrRecordWithAdditionalData.length;
      recordWithAdditionalDataIndex++
    ) {
      const recordWithAdditionalData = arrRecordWithAdditionalData[recordWithAdditionalDataIndex];
      if (recordWithAdditionalData.hasParameterChanged) {
        if (
          recordWithAdditionalData.errorTextForFoodItemName ||
          recordWithAdditionalData.errorTextForProductCode ||
          recordWithAdditionalData.errorTextForCount
        ) {
          firstRecordIdWithInputError = recordWithAdditionalData.recordId;
          break;
        }
        hasUpdatedRecord = true;
      }
    }
    if (firstRecordIdWithInputError) {
      scrollIntoViewForInvalidInput(firstRecordIdWithInputError);
    } else if (!hasUpdatedRecord) {
      redirectToHomeFromEdit();
    } else {
      submitToUpdate();
    }
  };

  /**
   * Automatically scroll to the first record card with input error to assist the user in locating the first input error
   * @param {number} firstRecordIdWithInputError - Id of the first record with input error
   * @returns {boolean} true or false - To indicate if the count input contains only numbers
   */
  const scrollIntoViewForInvalidInput = (firstRecordIdWithInputError) => {
    const element = document.querySelector(`[id="${firstRecordIdWithInputError}"]`);
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  /**
   * Check if count input only contains numbers. If yes, check if the number is in the range of 0 - 999.
   * @param {string} countInput - Text input under 'Count' section
   * @returns {boolean} true or false - To indicate if the count input contains only numbers
   */
  const checkIsCountInputValid = (countInput) => {
    const regEx = /^[0-9]+$/;
    if (regEx.test(countInput)) {
      const countInputInNumber = Number(countInput);
      if (countInputInNumber >= 0 && countInputInNumber <= 999) {
        return true;
      }
    }
    return false;
  };

  /**
   * Update the locationId in the inventory record with additional data object and add an attribute 'hasParameterChanged' to indicate that the record
   * needs to be filtered for updated in the Backend
   * @param {Object} event
   * @param {number} recordId - Id of inventory record where there is a change in its key
   */
  const onChangeTextFieldForLocation = (event, recordId) => {
    const { value } = event.target;
    const arrUpdatedRecordWithAdditionalData = arrRecordWithAdditionalData.map(
      (recordWithAdditionalData) => {
        if (recordWithAdditionalData.recordId === recordId) {
          const updatedRecordWithAdditionalData = { ...recordWithAdditionalData };
          const selectedLocation = arrLocation.find((location) => location.name === value);
          updatedRecordWithAdditionalData.locationId = selectedLocation.id;
          updatedRecordWithAdditionalData.locationName = value;
          updatedRecordWithAdditionalData.hasParameterChanged = true;
          return updatedRecordWithAdditionalData;
        }
        return recordWithAdditionalData;
      }
    );
    setArrRecordWithAdditionalData(arrUpdatedRecordWithAdditionalData);
  };

  /**
   * Update the count in the inventory record with additional data object and add an attribute 'hasParameterChanged' to indicate that the record needs
   * to be filtered for updated in the Backend. The 'hasCountChanged' attribute is used in Backend to retrieved the corresponding out record so that
   * the updated count can be aligned with the in record. Validate the input value and include an error message for invalid input, which will be display
   * under 'Count' section, to inform the user.
   * @param {Object} event
   * @param {number} recordId - Id of record where there is a change in its key
   */
  const onChangeTextFieldForCount = (event, recordId) => {
    const { value } = event.target;
    const arrUpdatedRecordWithAdditionalData = arrRecordWithAdditionalData.map(
      (recordWithAdditionalData) => {
        if (recordWithAdditionalData.recordId === recordId) {
          const updatedRecordWithAdditionalData = { ...recordWithAdditionalData };
          updatedRecordWithAdditionalData.hasParameterChanged = true;
          updatedRecordWithAdditionalData.hasCountChanged = true;
          if (checkIsCountInputValid(value)) {
            updatedRecordWithAdditionalData.count = Number(value);
            delete updatedRecordWithAdditionalData.errorTextForCount;
          } else {
            updatedRecordWithAdditionalData.count = value;
            if (!value) {
              updatedRecordWithAdditionalData.errorTextForCount = 'Count should not be empty';
            } else {
              updatedRecordWithAdditionalData.errorTextForCount =
                'Count should be a number that is between 0 to 999';
            }
          }
          return updatedRecordWithAdditionalData;
        }
        return recordWithAdditionalData;
      }
    );
    setArrRecordWithAdditionalData(arrUpdatedRecordWithAdditionalData);
  };

  /**
   * Update the food item in the record with additional data object from the selection list after selecting a product code from the Dropdown list,
   * and add an attribute 'hasParameterChanged' to indicate that the record needs to be filtered for updated in the Backend.
   * Note: Batch code is made up of a date and the food item id, hence it's also updated when the food item is updated
   * @param {Object} event
   * @param {string} newValue - Selected value
   * @param {number} recordId - Id of record where there is a change in its key
   */
  const onChangeTextFieldForFoodItemNameSelection = (event, newValue, recordId) => {
    const arrUpdatedRecordWithAdditionalData = arrRecordWithAdditionalData.map(
      (recordWithAdditionalData) => {
        if (recordWithAdditionalData.recordId === recordId) {
          const updatedRecordWithAdditionalData = { ...recordWithAdditionalData };
          updatedRecordWithAdditionalData.hasParameterChanged = true;
          if (!newValue) {
            updatedRecordWithAdditionalData.foodItemId = null;
            updatedRecordWithAdditionalData.foodItemName = null;
            updatedRecordWithAdditionalData.productCode = null;
            updatedRecordWithAdditionalData.errorTextForFoodItemName = 'Item should not be empty';
            updatedRecordWithAdditionalData.errorTextForProductCode = 'SKU should not be empty';
          } else {
            const selectedFoodItem = arrFoodItem.find((foodItem) => foodItem.name === newValue);
            updatedRecordWithAdditionalData.foodItemId = selectedFoodItem.id;
            updatedRecordWithAdditionalData.foodItemName = newValue;
            updatedRecordWithAdditionalData.productCode = selectedFoodItem.productCode;
            updatedRecordWithAdditionalData.batchCode = updatedRecordWithAdditionalData.batchCode
              .substring(0, 11)
              .concat('', selectedFoodItem.id);
            delete updatedRecordWithAdditionalData.errorTextForFoodItemName;
            delete updatedRecordWithAdditionalData.errorTextForProductCode;
          }
          return updatedRecordWithAdditionalData;
        }
        return recordWithAdditionalData;
      }
    );
    setArrRecordWithAdditionalData(arrUpdatedRecordWithAdditionalData);
  };

  /**
   * Update the food item in the record with additional data object from the selection list after selecting a product code from the Dropdown list,
   * and add an attribute 'hasParameterChanged' to indicate that the record needs to be filtered for updated in the Backend.
   * Note: Batch code is made up of a date and the food item id, hence it's also updated when the food item is updated
   * @param {Object} event
   * @param {string} newValue - Selected value
   * @param {number} recordId - Id of record where there is a change in its key
   */
  const onChangeTextFieldForProductCodeSelection = (event, newValue, recordId) => {
    const arrUpdatedRecordWithAdditionalData = arrRecordWithAdditionalData.map(
      (recordWithAdditionalData) => {
        if (recordWithAdditionalData.recordId === recordId) {
          const updatedRecordWithAdditionalData = { ...recordWithAdditionalData };
          updatedRecordWithAdditionalData.hasParameterChanged = true;
          if (!newValue) {
            updatedRecordWithAdditionalData.foodItemId = null;
            updatedRecordWithAdditionalData.foodItemName = null;
            updatedRecordWithAdditionalData.productCode = null;
            updatedRecordWithAdditionalData.errorTextForFoodItemName = 'Item should not be empty';
            updatedRecordWithAdditionalData.errorTextForProductCode = 'SKU should not be empty';
          } else {
            const selectedFoodItem = arrFoodItem.find(
              (foodItem) => foodItem.productCode === newValue
            );
            updatedRecordWithAdditionalData.foodItemId = selectedFoodItem.id;
            updatedRecordWithAdditionalData.foodItemName = selectedFoodItem.name;
            updatedRecordWithAdditionalData.productCode = newValue;
            updatedRecordWithAdditionalData.batchCode = updatedRecordWithAdditionalData.batchCode
              .substring(0, 11)
              .concat('', selectedFoodItem.id);
            delete updatedRecordWithAdditionalData.errorTextForFoodItemName;
            delete updatedRecordWithAdditionalData.errorTextForProductCode;
          }
          return updatedRecordWithAdditionalData;
        }
        return recordWithAdditionalData;
      }
    );
    setArrRecordWithAdditionalData(arrUpdatedRecordWithAdditionalData);
  };

  /**
   * Auto fill all the record with additional data with the Item and SKU of the first record on the list. If there is input error in the Item or
   * SKU of the first record card, the button for auto fill will be disabled.
   */
  const onClickAutoFill = () => {
    const foodItemNameToBeFilledWith = arrRecordWithAdditionalData[0].foodItemName;
    const productCodeToBeFilledWith = arrRecordWithAdditionalData[0].productCode;
    const foodItemIdToBeFilledWith = arrRecordWithAdditionalData[0].foodItemId;

    const arrUpdatedRecordWithAdditionalData = arrRecordWithAdditionalData.map(
      (recordWithAdditionalData) => {
        const updatedRecordWithAdditionalData = { ...recordWithAdditionalData };
        updatedRecordWithAdditionalData.hasParameterChanged = true;
        updatedRecordWithAdditionalData.foodItemName = foodItemNameToBeFilledWith;
        updatedRecordWithAdditionalData.foodItemId = foodItemIdToBeFilledWith;
        updatedRecordWithAdditionalData.productCode = productCodeToBeFilledWith;
        updatedRecordWithAdditionalData.batchCode = updatedRecordWithAdditionalData.batchCode
          .substring(0, 11)
          .concat('', foodItemIdToBeFilledWith);
        delete updatedRecordWithAdditionalData.errorTextForFoodItemName;
        delete updatedRecordWithAdditionalData.errorTextForProductCode;
        return updatedRecordWithAdditionalData;
      }
    );
    setArrRecordWithAdditionalData(arrUpdatedRecordWithAdditionalData);
  };

  return (
    <>
      {showLoader || !arrRecordWithAdditionalData ? (
        <Loader />
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{ paddingTop: 4, paddingBottom: 8 }}
        >
          <Grid item align="left" sx={{ width: '100%', paddingLeft: '41px' }}>
            <StyledReturnButton onClick={onClickCancel}>
              <ArrowCircleLeftIcon sx={{ fontSize: '300%' }} />
              <Typography
                sx={{ position: 'relative', top: '3px', fontSize: '26px', paddingLeft: 1 }}
              >
                Return To Home
              </Typography>
            </StyledReturnButton>
          </Grid>
          <Grid
            item
            sx={{ width: '80%', paddingTop: 1, paddingLeft: 1, paddingRight: 1, paddingBottom: 2 }}
          >
            <Grid container direction="column">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                spacing={20}
                sx={{ padding: '16px 8px 8px 8px' }}
              >
                <Typography align="right" sx={{ fontSize: 20, color: '#969D9E' }}>
                  {`Found ${arrRecordWithAdditionalData.length} Record(s)`}
                </Typography>
                <StyledAutoFillButton
                  variant="outlined"
                  disabled={
                    arrRecordWithAdditionalData[0].errorTextForFoodItemName ||
                    arrRecordWithAdditionalData[0].errorTextForProductCode
                      ? true
                      : false
                  }
                  onClick={onClickAutoFill}
                >
                  Auto-Fill All Item and SKU
                </StyledAutoFillButton>
              </Stack>
              {arrRecordWithAdditionalData.map((recordWithAdditionalData) => {
                return (
                  <Box
                    key={recordWithAdditionalData.recordId}
                    id={recordWithAdditionalData.recordId}
                    sx={{ display: 'block', padding: 1, borderRadius: 3 }}
                  >
                    <Card elevation={0} sx={{ borderRadius: 3 }}>
                      <Box
                        sx={{
                          overflow: 'auto',
                          display: 'flex',
                          border: 3,
                          borderColor: '#1D4486',
                          borderRadius: 3,
                        }}
                      >
                        <CardActionArea
                          sx={{ maxWidth: '30%', minWidth: '20%', height: 'auto' }}
                          onClick={() => onClickCardMedia(recordWithAdditionalData.imageUrl)}
                        >
                          <CardMedia
                            component="img"
                            image={recordWithAdditionalData.imageUrl}
                            alt="CPCS"
                          />
                        </CardActionArea>

                        <CardContent sx={{ width: '100%', paddingTop: 2 }}>
                          <Grid
                            sx={{
                              display: 'block',
                              alignItems: 'flex-start',
                              paddingBottom: 5,
                              columnGap: 4,
                            }}
                          >
                            {/* Note: Client terms tray serial as batch Code, hence in the Frontend, tray serial is displayed as batch code */}
                            <Grid sx={{ display: 'flex', padding: 0.5 }}>
                              <Typography sx={{ fontSize: 16 }}>
                                Batch Code: {recordWithAdditionalData.traySerial}
                              </Typography>
                            </Grid>
                            <Grid sx={{ display: 'flex', padding: 0.5 }}>
                              <Typography sx={{ fontSize: 16 }}>
                                Weight: {recordWithAdditionalData.weight.toFixed(2)} kg
                              </Typography>
                            </Grid>
                            <Grid sx={{ display: 'flex', padding: 0.5 }}>
                              <Typography sx={{ fontSize: 16 }}>
                                Time Taken: {recordWithAdditionalData.time}
                              </Typography>
                            </Grid>
                            <Grid sx={{ display: 'flex', padding: 0.5 }}>
                              <Typography sx={{ fontSize: 16 }}>
                                In/Out: {recordWithAdditionalData.isGoingIntoStorage ? 'In' : 'Out'}
                              </Typography>
                            </Grid>
                            <Grid sx={{ display: 'flex', padding: 0.5 }}>
                              <Typography sx={{ fontSize: 16 }}>
                                Last Edit Time: {recordWithAdditionalData.lastEditTime}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid sx={{ display: 'flex', columnGap: 6 }}>
                            <StyledAutocomplete
                              id="sku"
                              sx={{ margin: 1, width: '20ch' }}
                              value={recordWithAdditionalData.productCode}
                              options={arrProductCodeOption}
                              onChange={(event, newValue) =>
                                onChangeTextFieldForProductCodeSelection(
                                  event,
                                  newValue,
                                  recordWithAdditionalData.recordId
                                )
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="SKU"
                                  InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                  }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  helperText={recordWithAdditionalData.errorTextForProductCode}
                                  error={
                                    recordWithAdditionalData.errorTextForProductCode ? true : false
                                  }
                                  variant="standard"
                                  required
                                />
                              )}
                            />
                            <StyledAutocomplete
                              id="item"
                              sx={{ margin: 1, width: '40ch' }}
                              value={recordWithAdditionalData.foodItemName}
                              options={arrFoodItemNameOption}
                              onChange={(event, newValue) =>
                                onChangeTextFieldForFoodItemNameSelection(
                                  event,
                                  newValue,
                                  recordWithAdditionalData.recordId
                                )
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Item"
                                  InputProps={{
                                    ...params.InputProps,
                                    style: { fontSize: 16 },
                                    type: 'search',
                                  }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  helperText={recordWithAdditionalData.errorTextForFoodItemName}
                                  error={
                                    recordWithAdditionalData.errorTextForFoodItemName ? true : false
                                  }
                                  variant="standard"
                                  required
                                />
                              )}
                            />
                            {recordWithAdditionalData.isGoingIntoStorage ? (
                              <>
                                <TextField
                                  label="Count"
                                  id="count"
                                  variant="standard"
                                  value={recordWithAdditionalData.count}
                                  sx={{ margin: 1, width: '20ch' }}
                                  InputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  helperText={recordWithAdditionalData.errorTextForCount}
                                  error={recordWithAdditionalData.errorTextForCount ? true : false}
                                  onChange={(event) =>
                                    onChangeTextFieldForCount(
                                      event,
                                      recordWithAdditionalData.recordId
                                    )
                                  }
                                  required
                                />
                                <TextField
                                  id="cold-room"
                                  select
                                  label="Cold Room"
                                  variant="standard"
                                  value={recordWithAdditionalData.locationName}
                                  sx={{ margin: 1, width: '15ch' }}
                                  InputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  onChange={(event) =>
                                    onChangeTextFieldForLocation(
                                      event,
                                      recordWithAdditionalData.recordId
                                    )
                                  }
                                >
                                  {arrLocation.map((location) => (
                                    <MenuItem
                                      key={location.name}
                                      value={location.name}
                                      sx={{ fontSize: 16, justifyContent: 'flex-start' }}
                                    >
                                      {location.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </>
                            ) : (
                              <>
                                <TextField
                                  id="count"
                                  label="Count"
                                  variant="standard"
                                  value={recordWithAdditionalData.count}
                                  sx={{ margin: 1, width: '20ch' }}
                                  InputProps={{ style: { fontSize: 16 }, readOnly: true }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                />
                                <TextField
                                  id="cold-room"
                                  label="Cold Room"
                                  variant="standard"
                                  value={recordWithAdditionalData.locationName}
                                  sx={{ margin: 1, width: '15ch' }}
                                  InputProps={{ style: { fontSize: 16 }, readOnly: true }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                />
                              </>
                            )}
                          </Grid>
                        </CardContent>
                      </Box>
                    </Card>
                  </Box>
                );
              })}

              <Stack direction="row" justifyContent="center" padding="28px">
                <StyledSubmitButton variant="contained" onClick={onClickSubmit}>
                  Submit
                </StyledSubmitButton>
              </Stack>
            </Grid>
          </Grid>

          {isDialogOpened && (
            <Dialog
              aria-label="max-width-image-dialog"
              fullWidth
              maxWidth="xl"
              onClose={onCloseDialog}
              open={isDialogOpened}
            >
              <DialogContent
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '150px',
                  minHeight: '50px',
                  paddingTop: 6,
                }}
              >
                <img width="100%" height="100%" src={imageUrlToExpand} alt="CPCS"></img>
                <IconButton
                  sx={{ position: 'absolute', right: 8, top: 8 }}
                  onClick={onClickCloseDialog}
                >
                  <CloseIcon />
                </IconButton>
              </DialogContent>
            </Dialog>
          )}
        </Grid>
      )}
    </>
  );
};

export default Edit;
