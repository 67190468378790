import { useState, useEffect, useCallback } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterModal from './FilterModal';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Loader from './Loader';
import usePrevious from './usePrevious.js';

const ARR_TABLE_HEAD_MAIN = [
  { id: 1, label: 'SKU', value: 'productCode', align: 'left', width: '140px', rowSpan: 2 },
  { id: 2, label: 'ITEM', value: 'foodItemName', align: 'left', width: '290px', rowSpan: 2 },
  {
    id: 3,
    label: 'PRODUCTION DATE',
    value: 'productionDate',
    align: 'center',
    width: '165px',
    rowSpan: 2,
  },
  { id: 4, label: 'WEIGHT(KG)', value: 'weight', align: 'center', width: '200px', colSpan: 2 },
  { id: 5, label: 'COUNT(PIECES)', value: 'count', align: 'center', width: '200px', colSpan: 2 },
  {
    id: 6,
    label: 'LAST TIME TAKEN',
    value: 'time',
    align: 'center',

    width: '195px',
    rowSpan: 2,
    canBeSorted: true,
  },
  { id: 7, label: 'COLD ROOM', value: 'locationName', align: 'center', width: '130px', rowSpan: 2 },
  { id: 8, label: 'EDIT', value: 'edit', align: 'center', width: '30px', rowSpan: 2 },
  {
    id: 9,
    label: 'LAST EDIT TIME',
    value: 'lastEditTime',
    align: 'center',
    width: '165px',
    rowSpan: 2,
    canBeSorted: true,
  },
];

const ARR_TABLE_HEAD_SUB = [
  { id: 1, label: 'Total', value: 'weightTotal', align: 'center', width: '100px' },
  { id: 2, label: 'Balance', value: 'weightBalance', align: 'center', width: '100px' },
  { id: 3, label: 'Total', value: 'countTotal', align: 'center', width: '100px' },
  { id: 4, label: 'Balance', value: 'countBalance', align: 'center', width: '100px' },
];

/**
 * Filter the array of batchDataWithAnalysis based on the selected filter options, If the arrSelectedOption is empty,
 * return the whole array of batchDataWithAnalysis
 * @param {Object[]} arrBatchDataWithAnalysis - Array of batchDataWithAnalysis
 * @param {Object[]} arrSelectedOption - Array of selected options for filtering
 * @returns {Object[]} arrFilteredBatchDataWithAnalysis - Array of batchDataWithAnalysis based on the filter options selected
 */
const getArrFilteredBatchDataWithAnalysis = (arrBatchDataWithAnalysis, arrSelectedOption) => {
  if (arrSelectedOption.length === 0) {
    return arrBatchDataWithAnalysis;
  } else {
    let arrFilteredBatchDataWithAnalysis = [];
    arrSelectedOption.forEach((selectedOption) => {
      const arrFilteredBatchDataWithAnalysisOfAFoodItem = arrBatchDataWithAnalysis.filter(
        (batchData) => batchData.foodItemId === selectedOption.value
      );
      arrFilteredBatchDataWithAnalysis = arrFilteredBatchDataWithAnalysis.concat(
        arrFilteredBatchDataWithAnalysisOfAFoodItem
      );
    });
    return arrFilteredBatchDataWithAnalysis;
  }
};

/**
 * Array of batch data to be displayed for the selected page
 * @param {Object[]} arrBatchDataWithAnalysis - Array of batchDataWithAnalysis
 * @param {number} pageNumber - Page number
 * @param {number} rowsPerPage - Rows per page
 * @returns {Object[]} arrBatchDataWithAnalysisForCurrentPage - Array of batchDataWithAnalysis to be displayed for the selected page
 */
const getArrBatchDataWithAnalysisForCurrentPage = (
  arrBatchDataWithAnalysis,
  pageNumber,
  rowsPerPage
) => {
  const arrBatchDataWithAnalysisForCurrentPage = arrBatchDataWithAnalysis.slice(
    (pageNumber - 1) * rowsPerPage,
    (pageNumber - 1) * rowsPerPage + rowsPerPage
  );

  return arrBatchDataWithAnalysisForCurrentPage;
};

/**
 * Calculate the sum of weight total, weight balance, count total and count balance for all the batch data displayed on the selected page.
 * If there is a batch with total count 'TBC' (i.e. which means this batch contains untagged records), the sum of the total count and
 * balance count will be 'TBC'.
 * @param {Object[]} arrBatchDataWithAnalysis - Array of batchDataWithAnalysis
 * @returns {Object} Object
 * @returns {Object} Object.sumOfWeightTotal - Sum of weight total of all the batch data displayed on the selected page
 * @returns {Object} Object.sumOfWeightBalance - Sum of weight balance of all the batch data displayed on the selected page
 * @returns {Object} Object.sumOfCountTotal - Sum of count total of all the batch data displayed on the selected page
 * @returns {Object} Object.sumOfCountBalance - Sum of count balance of all the batch data displayed on the selected page
 */
const calculateSumOfWeightAndCount = (arrBatchDataWithAnalysis) => {
  let sumOfWeightTotal = 0;
  let sumOfWeightBalance = 0;
  let sumOfCountTotal = 0;
  let sumOfCountBalance = 0;
  const batchDataWithCountTBC = arrBatchDataWithAnalysis.find(
    (batchData) => batchData.countTotal === 'TBC'
  );
  if (batchDataWithCountTBC) {
    sumOfCountTotal = 'TBC';
    sumOfCountBalance = 'TBC';
    arrBatchDataWithAnalysis.forEach((batchData) => {
      sumOfWeightTotal += batchData.weightTotal;
      sumOfWeightBalance += batchData.weightBalance;
    });
  } else {
    arrBatchDataWithAnalysis.forEach((batchData) => {
      sumOfWeightTotal += batchData.weightTotal;
      sumOfWeightBalance += batchData.weightBalance;
      sumOfCountTotal += batchData.countTotal;
      sumOfCountBalance += batchData.countBalance;
    });
  }

  return {
    sumOfWeightTotal,
    sumOfWeightBalance,
    sumOfCountTotal,
    sumOfCountBalance,
  };
};

/**
 * Get the displayed data for the selected page, which includes batch data , as well as the sum of weight total, weight balance, count total and count
 * balance for all the batch data displayed on the selected page
 * @param {Object[]} arrBatchDataWithAnalysis - Array of batchDataWithAnalysis
 * @param {number} pageNumber - Page number
 * @param {number} rowsPerPage - Rows per page
 * @returns {Object[]} arrBatchDataWithAnalysisForCurrentPage - Array of batchDataWithAnalysis to be displayed for the selected page
 * @returns {Object} sumOfWeightAndCountObject
 * @returns {Object} sumOfWeightAndCountObject.sumOfWeightTotal - Sum of weight total of all the batch data displayed on the selected page
 * @returns {Object} sumOfWeightAndCountObject.sumOfWeightBalance - Sum of weight balance of all the batch data displayed on the selected page
 * @returns {Object} sumOfWeightAndCountObject.sumOfCountTotal - Sum of count total of all the batch data displayed on the selected page
 * @returns {Object} sumOfWeightAndCountObject.sumOfCountBalance - Sum of count balance of all the batch data displayed on the selected page
 */
const getDisplayedDataForCurrentPage = (arrBatchDataWithAnalysis, pageNumber, rowsPerPage) => {
  const arrBatchDataWithAnalysisForCurrentPage = getArrBatchDataWithAnalysisForCurrentPage(
    arrBatchDataWithAnalysis,
    pageNumber,
    rowsPerPage
  );
  const sumOfWeightAndCountObject = calculateSumOfWeightAndCount(
    arrBatchDataWithAnalysisForCurrentPage
  );
  return {
    sumOfWeightAndCountObject,
    arrBatchDataWithAnalysisForCurrentPage,
  };
};

/**
 * Sort an array of elements in ascending order by the attribute
 * @param {Object[]} arrElement - Array of element to be sorted in ascending order
 * @param {string} attributeName - Attribute name to be sorted by
 */
const sortInAscendingOrder = (arrElement, attributeName) => {
  arrElement.sort((elementA, elementB) => {
    if (elementA[attributeName] > elementB[attributeName] || !elementB[attributeName]) {
      return 1;
    }
    return -1;
  });
};

/**
 * Sort an array of elements in descending order by the attribute
 * @param {Object[]} arrElement - Array of element to be sorted in descending order
 * @param {string} attributeName - Attribute name to be sorted by
 */
const sortInDescendingOrder = (arrElement, attributeName) => {
  arrElement.sort((elementA, elementB) => {
    if (elementA[attributeName] < elementB[attributeName] || !elementA[attributeName]) {
      return 1;
    }
    return -1;
  });
};

/**
 * Sort an array of elements in descending order or ascending order (previous selected order) by the attribute to be sorted
 * by (previous selected sort by)
 * @param {Object[]} arrElement - Array of element to be sorted in descending order
 * @param {number} sortBy - Number representation of the attribute to be sorted by
 * @param {string} sortOrder - Order to be sorted by, can be either 'asc' or 'desc'
 */
const sortInPreviousOrder = (arrElement, sortBy, sortOrder) => {
  const tableHead = ARR_TABLE_HEAD_MAIN.find((tableHead) => tableHead.id === sortBy);
  if (sortOrder === 'asc') {
    sortInAscendingOrder(arrElement, tableHead.value);
  } else {
    sortInDescendingOrder(arrElement, tableHead.value);
  }
};

const SingleArrowIcon = (sortOrder) => {
  if (sortOrder === 'asc') {
    return (
      <Grid container display="flex" direction="column" sx={{ width: 'auto' }}>
        <ArrowDropUpIcon fontSize="large" sx={{ color: '#E3705D' }} />
        <ArrowDropDownIcon fontSize="large" sx={{ color: '#969D9E' }} />
      </Grid>
    );
  } else {
    return (
      <Grid container display="flex" direction="column" sx={{ width: 'auto' }}>
        <ArrowDropUpIcon fontSize="large" sx={{ color: '#969D9E' }} />
        <ArrowDropDownIcon fontSize="large" sx={{ color: '#E3705D' }} />
      </Grid>
    );
  }
};

const DoubleArrowIcon = () => {
  return (
    <Grid container display="flex" direction="column" sx={{ width: 'auto' }}>
      <ArrowDropUpIcon fontSize="large" sx={{ color: '#969D9E' }} />
      <ArrowDropDownIcon fontSize="large" sx={{ color: '#969D9E' }} />
    </Grid>
  );
};

const StyledButton = styled(Button)({
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#99F5F0',
    borderRadius: '8px',
  },
});

const StyledHeaderTableRow = styled(TableRow)({
  backgroundColor: '#122C57',
});

const StyledTableBody = styled(TableBody)({
  '& .MuiTableRow-root:last-child': {
    '& .MuiTableCell-root': {
      borderColor: '#969D9E',
    },
  },
});

const Home = (props) => {
  const {
    arrBatchDataWithAnalysis,
    arrFilteredAndSortedBatchDataWithAnalysis,
    arrSelectedOptionToBeFilteredBy,
    filterBy,
    sortBy,
    sortOrder,
    initialiseUserDataAfterRefresh,
    updateArrRecordWithAdditionalDataOfSelectedBatch,
    updateFilterDetailsAfterConfirmation,
    updateSortDetails,
  } = props;

  const [lastFetchDataTime, setLastFetchDataTime] = useState('');
  const [page, setPage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [
    arrFilteredAndSortedBatchDataWithAnalysisForCurrentPage,
    setArrFilteredAndSortedBatchDataWithAnalysisForCurrentPage,
  ] = useState(null);
  const [arrFoodItemOptionForFilter, setArrFoodItemOptionForFilter] = useState(null);
  const [arrFoodItemForFilter, setArrFoodItemForFilter] = useState(null);
  const [shouldOpenFilterModal, setShouldOpenFilterModal] = useState(false);
  const [currentFilterBy, setCurrentFilterBy] = useState(null);
  const [sumOfWeightAndCountObject, setSumOfWeightAndCountObject] = useState(null);
  const previousArrSelectedOptionToBeFilteredBy = usePrevious(arrSelectedOptionToBeFilteredBy);
  const previousFilterBy = usePrevious(filterBy);
  const previousSortBy = usePrevious(sortBy);
  const previousSortOrder = usePrevious(sortOrder);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const rowsPerPage = 10;

  const fetchUserData = useCallback(async () => {
    try {
      setShowLoader(true);
      const accessToken = await getAccessTokenSilently();
      const response = await axios.post(
        '/web-app/fetch-user-data',
        {},
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      const { data } = response;
      let arrNewFilteredAndSortedBatchDataWithAnalysis = [];
      if (arrSelectedOptionToBeFilteredBy) {
        arrNewFilteredAndSortedBatchDataWithAnalysis = getArrFilteredBatchDataWithAnalysis(
          data.arrBatchDataWithAnalysis,
          arrSelectedOptionToBeFilteredBy
        );
      } else {
        arrNewFilteredAndSortedBatchDataWithAnalysis = data.arrBatchDataWithAnalysis;
      }
      sortInPreviousOrder(arrNewFilteredAndSortedBatchDataWithAnalysis, sortBy, sortOrder);

      const displayedDataForCurrentPage = getDisplayedDataForCurrentPage(
        arrNewFilteredAndSortedBatchDataWithAnalysis,
        page,
        rowsPerPage
      );

      // Default offset of '+08:00' if there isnt any data in the past 4 days
      let offset = '';
      if (arrNewFilteredAndSortedBatchDataWithAnalysis.length !== 0) {
        offset =
          arrNewFilteredAndSortedBatchDataWithAnalysis[0].arrRecordWithAdditionalData[0].offset;
      } else {
        offset = '+08:00';
      }
      const newLastFetchDataTime = moment.utc().utcOffset(offset).format('YYYY-MM-DD HH:mm');

      initialiseUserDataAfterRefresh(data, arrNewFilteredAndSortedBatchDataWithAnalysis);
      setArrFoodItemForFilter(data.arrFoodItemForFilter);
      setCurrentFilterBy(filterBy);
      setArrFilteredAndSortedBatchDataWithAnalysisForCurrentPage(
        displayedDataForCurrentPage.arrBatchDataWithAnalysisForCurrentPage
      );
      setSumOfWeightAndCountObject(displayedDataForCurrentPage.sumOfWeightAndCountObject);
      setLastFetchDataTime(newLastFetchDataTime);
      setShowLoader(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert('Your session has expired. Please login again.');
      } else {
        alert('Error when retrieving user data. Please notify admin.');
      }
      navigate('/login', { replace: true });
    }
  }, [
    arrSelectedOptionToBeFilteredBy,
    filterBy,
    page,
    sortBy,
    sortOrder,
    getAccessTokenSilently,
    initialiseUserDataAfterRefresh,
    navigate,
  ]);

  const updateDisplayedDataForCurrentPageAfterFilter = useCallback(() => {
    const displayedDataForCurrentPage = getDisplayedDataForCurrentPage(
      arrFilteredAndSortedBatchDataWithAnalysis,
      1,
      rowsPerPage
    );
    setPage(1);
    setArrFilteredAndSortedBatchDataWithAnalysisForCurrentPage(
      displayedDataForCurrentPage.arrBatchDataWithAnalysisForCurrentPage
    );
    setSumOfWeightAndCountObject(displayedDataForCurrentPage.sumOfWeightAndCountObject);
  }, [arrFilteredAndSortedBatchDataWithAnalysis]);

  const updateDisplayedDataForCurrentPageAfterSort = useCallback(() => {
    const displayedDataForCurrentPage = getDisplayedDataForCurrentPage(
      arrFilteredAndSortedBatchDataWithAnalysis,
      page,
      rowsPerPage
    );
    setArrFilteredAndSortedBatchDataWithAnalysisForCurrentPage(
      displayedDataForCurrentPage.arrBatchDataWithAnalysisForCurrentPage
    );
    setSumOfWeightAndCountObject(displayedDataForCurrentPage.sumOfWeightAndCountObject);
  }, [page, arrFilteredAndSortedBatchDataWithAnalysis]);

  useEffect(() => {
    if (isAuthenticated) {
      if (!lastFetchDataTime) {
        fetchUserData();
      } else if (
        previousArrSelectedOptionToBeFilteredBy !== arrSelectedOptionToBeFilteredBy ||
        previousArrSelectedOptionToBeFilteredBy !== arrSelectedOptionToBeFilteredBy
      ) {
        updateDisplayedDataForCurrentPageAfterFilter();
      } else if (previousSortBy !== sortBy || previousSortOrder !== sortOrder) {
        updateDisplayedDataForCurrentPageAfterSort();
      }

      // Currently re-fresh every 5 mins, as per requested by the client
      const intervalId = setInterval(() => {
        fetchUserData();
      }, 300000);

      return () => clearInterval(intervalId);
    } else if (isLoading) {
      setShowLoader(true);
    } else {
      navigate('/login', { replace: true });
    }
  }, [
    isAuthenticated,
    isLoading,
    lastFetchDataTime,
    arrSelectedOptionToBeFilteredBy,
    previousArrSelectedOptionToBeFilteredBy,
    previousSortBy,
    previousSortOrder,
    sortBy,
    sortOrder,
    fetchUserData,
    navigate,
    updateDisplayedDataForCurrentPageAfterFilter,
    updateDisplayedDataForCurrentPageAfterSort,
  ]);

  const onChangePagination = (event, value) => {
    const displayedDataForCurrentPage = getDisplayedDataForCurrentPage(
      arrFilteredAndSortedBatchDataWithAnalysis,
      value,
      rowsPerPage
    );

    setPage(value);
    setArrFilteredAndSortedBatchDataWithAnalysisForCurrentPage(
      displayedDataForCurrentPage.arrBatchDataWithAnalysisForCurrentPage
    );
    setSumOfWeightAndCountObject(displayedDataForCurrentPage.sumOfWeightAndCountObject);
  };

  const onClickEdit = (batchDataWithAnalysis) => {
    const arrRecordWithAdditionalDataOfNewSelectedBatch =
      batchDataWithAnalysis.arrRecordWithAdditionalData;
    updateArrRecordWithAdditionalDataOfSelectedBatch(arrRecordWithAdditionalDataOfNewSelectedBatch);
    redirectToEditFromHome();
  };

  const onClickFilterIcon = (headerIdToFilterBy) => {
    let arrAllOption = [];
    if (headerIdToFilterBy === 2) {
      arrAllOption = arrFoodItemForFilter.map((foodItemForFilter) => {
        return {
          label: foodItemForFilter.foodItemName,
          value: foodItemForFilter.foodItemId,
        };
      });
    } else {
      arrAllOption = arrFoodItemForFilter.map((foodItemForFilter) => {
        return {
          label: foodItemForFilter.productCode,
          value: foodItemForFilter.foodItemId,
        };
      });
    }
    setCurrentFilterBy(headerIdToFilterBy);
    setShouldOpenFilterModal(true);
    setArrFoodItemOptionForFilter(arrAllOption);
  };

  const onClickRemoveFilter = () => {
    setShouldOpenFilterModal(false);
    // This is to prevent the removal of filter for a header when the user clicks on another non-filtered header but accidentally clicks
    // the 'remove filter' button
    if (filterBy === currentFilterBy) {
      updateFilterDetailsAfterConfirmation(null, arrBatchDataWithAnalysis, []);
    }
  };

  const onClickSort = (headerIdToSortBy, headerValueToSortBy) => {
    const isAsc = sortBy === headerIdToSortBy && sortOrder === 'asc';
    const newSortBy = isAsc ? 'desc' : 'asc';
    if (isAsc) {
      sortInDescendingOrder(arrFilteredAndSortedBatchDataWithAnalysis, headerValueToSortBy);
    } else {
      sortInAscendingOrder(arrFilteredAndSortedBatchDataWithAnalysis, headerValueToSortBy);
    }
    updateSortDetails(newSortBy, headerIdToSortBy);
  };

  const onClickSubmitFilter = (arrSelectedOption) => {
    const arrFilteredBatchDataWithAnalysis = getArrFilteredBatchDataWithAnalysis(
      arrBatchDataWithAnalysis,
      arrSelectedOption
    );
    sortInPreviousOrder(arrFilteredBatchDataWithAnalysis, sortBy, sortOrder);

    setShouldOpenFilterModal(false);
    updateFilterDetailsAfterConfirmation(
      currentFilterBy,
      arrFilteredBatchDataWithAnalysis,
      arrSelectedOption
    );
  };

  const closeFilterModal = () => {
    if (arrSelectedOptionToBeFilteredBy.length === 0) {
      setCurrentFilterBy(null);
    } else {
      setCurrentFilterBy(filterBy);
    }
    setShouldOpenFilterModal(false);
  };

  const redirectToEditFromHome = () => {
    navigate('/edit');
  };

  return (
    <>
      {showLoader || !arrFilteredAndSortedBatchDataWithAnalysisForCurrentPage ? (
        <Loader />
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          width="100%"
          sx={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 8, paddingRight: 8 }}
        >
          <Grid item width="100%">
            <Grid container width="100%" justifyContent="flex-end" sx={{ paddingBottom: 3 }}>
              <Typography
                sx={{ fontSize: 18, fontWeight: 'bold', color: '#969D9E' }}
              >{`Last updated: ${lastFetchDataTime}`}</Typography>
            </Grid>
            <Paper align="center">
              <TableContainer sx={{ borderRadius: 1 }}>
                <Table sx={{ wdith: '100%' }}>
                  <TableHead>
                    <StyledHeaderTableRow>
                      {ARR_TABLE_HEAD_MAIN.map((tableHeadMain) => {
                        return (
                          <TableCell
                            key={tableHeadMain.id}
                            rowSpan={tableHeadMain.rowSpan}
                            colSpan={tableHeadMain.colSpan}
                            width={tableHeadMain.width}
                            align={tableHeadMain.align}
                            sx={{ paddingTop: 2 }}
                          >
                            {tableHeadMain.id === 4 ||
                            tableHeadMain.id === 5 ||
                            tableHeadMain.id === 7 ||
                            tableHeadMain.id === 8 ? (
                              <Typography
                                sx={{ color: '#FFFFFF', fontSize: 16, fontWeight: 'bold' }}
                              >
                                {tableHeadMain.label}
                              </Typography>
                            ) : tableHeadMain.id === 1 || tableHeadMain.id === 2 ? (
                              <Grid container display="flex" direction="row" width="auto">
                                <IconButton onClick={() => onClickFilterIcon(tableHeadMain.id)}>
                                  {tableHeadMain.id === filterBy ? (
                                    <FilterAltIcon sx={{ color: '#E3705D' }} />
                                  ) : (
                                    <FilterAltIcon sx={{ color: '#969D9E' }} />
                                  )}
                                </IconButton>
                                <TableSortLabel
                                  active
                                  direction={sortBy === tableHeadMain.id ? sortOrder : 'desc'}
                                  IconComponent={
                                    sortBy === tableHeadMain.id
                                      ? () => SingleArrowIcon(sortOrder)
                                      : DoubleArrowIcon
                                  }
                                  onClick={() => onClickSort(tableHeadMain.id, tableHeadMain.value)}
                                >
                                  <Typography
                                    sx={{ color: '#FFFFFF', fontSize: 16, fontWeight: 'bold' }}
                                  >
                                    {tableHeadMain.label}
                                  </Typography>
                                </TableSortLabel>
                              </Grid>
                            ) : (
                              <TableSortLabel
                                active
                                direction={sortBy === tableHeadMain.id ? sortOrder : 'desc'}
                                IconComponent={
                                  sortBy === tableHeadMain.id
                                    ? () => SingleArrowIcon(sortOrder)
                                    : DoubleArrowIcon
                                }
                                onClick={() => onClickSort(tableHeadMain.id, tableHeadMain.value)}
                              >
                                <Typography
                                  sx={{ color: '#FFFFFF', fontSize: 16, fontWeight: 'bold' }}
                                >
                                  {tableHeadMain.label}
                                </Typography>
                              </TableSortLabel>
                            )}
                          </TableCell>
                        );
                      })}
                    </StyledHeaderTableRow>

                    <StyledHeaderTableRow sx={{ borderTop: 2.5, borderColor: '#969D9E' }}>
                      {ARR_TABLE_HEAD_SUB.map((tableHeadSub) => {
                        return (
                          <TableCell
                            key={tableHeadSub.id}
                            width={tableHeadSub.width}
                            align={tableHeadSub.align}
                          >
                            <Typography sx={{ color: '#FFFFFF', fontSize: 16, fontWeight: 'bold' }}>
                              {tableHeadSub.label}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </StyledHeaderTableRow>
                  </TableHead>

                  <StyledTableBody>
                    {arrFilteredAndSortedBatchDataWithAnalysisForCurrentPage.map((batchData) => {
                      return (
                        <TableRow
                          key={`${batchData.batchCode}`}
                          sx={{
                            backgroundColor: batchData.hasErrorInRecords ? '#E3705D' : null,
                          }}
                        >
                          <TableCell align="left" width="140px">
                            <Typography sx={{ wordWrap: 'break-word' }}>
                              {batchData.productCode}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" width="290px">
                            <Typography
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '2',
                                WebkitBoxOrient: 'vertical',
                              }}
                            >
                              {batchData.foodItemName}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" width="165px">
                            <Typography c>{batchData.productionDate}</Typography>
                          </TableCell>
                          <TableCell align="center" width="100px">
                            <Typography sx={{ wordWrap: 'break-word' }}>
                              {batchData.weightTotal.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" width="100px">
                            <Typography sx={{ wordWrap: 'break-word' }}>
                              {batchData.weightBalance < 0.005
                                ? '0.00'
                                : batchData.weightBalance.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" width="100px">
                            <Typography sx={{ wordWrap: 'break-word' }}>
                              {batchData.countTotal}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" width="100px">
                            <Typography sx={{ wordWrap: 'break-word' }}>
                              {batchData.countBalance}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" width="195px">
                            <Typography sx={{ wordWrap: 'break-word' }}>
                              {batchData.time}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" width="130px">
                            <Typography sx={{ wordWrap: 'break-word' }}>
                              {batchData.locationName}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" width="30px">
                            <StyledButton
                              sx={{ width: '30px' }}
                              onClick={() => onClickEdit(batchData)}
                            >
                              <EditIcon fontSize="large" sx={{ color: '#969D9E' }} />
                            </StyledButton>
                          </TableCell>
                          <TableCell align="center" width="165px">
                            <Typography sx={{ wordWrap: 'break-word' }}>
                              {batchData.lastEditTime}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </StyledTableBody>
                  <TableBody>
                    <TableRow
                      key={`${page} ${sumOfWeightAndCountObject.sumOfWeightTotal.toFixed(2)}`}
                    >
                      <TableCell align="right" colSpan={3} sx={{ borderBottom: 0 }}>
                        <Typography
                          sx={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                            wordWrap: 'break-word',
                            width: '650px',
                          }}
                        >
                          SUM
                        </Typography>
                      </TableCell>
                      <TableCell align="center" sx={{ borderColor: '#122C57' }}>
                        <Typography
                          sx={{ fontWeight: 'bold', wordWrap: 'break-word', width: '100px' }}
                        >
                          {sumOfWeightAndCountObject.sumOfWeightTotal.toFixed(2)}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" sx={{ borderColor: '#122C57' }}>
                        <Typography
                          sx={{ fontWeight: 'bold', wordWrap: 'break-word', width: '100px' }}
                        >
                          {sumOfWeightAndCountObject.sumOfWeightBalance < 0.005
                            ? '0.00'
                            : sumOfWeightAndCountObject.sumOfWeightBalance.toFixed(2)}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" sx={{ borderColor: '#122C57' }}>
                        <Typography
                          sx={{ fontWeight: 'bold', wordWrap: 'break-word', width: '100px' }}
                        >
                          {sumOfWeightAndCountObject.sumOfCountTotal}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" sx={{ borderColor: '#122C57' }}>
                        <Typography
                          sx={{ fontWeight: 'bold', wordWrap: 'break-word', width: '100px' }}
                        >
                          {sumOfWeightAndCountObject.sumOfCountBalance}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                sx={{ display: 'inline-flex', paddingTop: 3, paddingBottom: 2 }}
                count={Math.ceil(arrFilteredAndSortedBatchDataWithAnalysis.length / rowsPerPage)}
                page={page}
                onChange={onChangePagination}
                showFirstButton
                showLastButton
              />
            </Paper>
          </Grid>
          {shouldOpenFilterModal && (
            <FilterModal
              arrAllOption={arrFoodItemOptionForFilter}
              arrPreviousSelectedOption={
                previousFilterBy === currentFilterBy ? arrSelectedOptionToBeFilteredBy : []
              }
              filterBy={currentFilterBy}
              nameOfFilterBy={currentFilterBy === 2 ? 'ITEM' : 'SKU'}
              shouldOpen={shouldOpenFilterModal}
              close={closeFilterModal}
              onClickRemove={onClickRemoveFilter}
              onClickSubmit={onClickSubmitFilter}
            />
          )}
        </Grid>
      )}
    </>
  );
};

export default Home;
