import { useState, useEffect, useCallback } from 'react';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from './Loader';

const StyledReturnButton = styled(Button)({
  textTransform: 'none',
  border: 'none',
  padding: '10px',
  color: '#1C406E',
  '&:hover': {
    textDecoration: 'underline',
    background: 'transparent',
  },
});

const StyledSubmitButton = styled(Button)({
  fontSize: '20px',
  fontWeight: 'bold',
  backgroundColor: '#63C6CA',
  borderRadius: '24px',
  width: '200px',
  '&:hover': {
    backgroundColor: '#1D4486',
    borderRadius: '24px',
  },
});

const Tag = () => {
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [imageUrlToExpand, setImageUrlToExpand] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [arrRecordWithAdditionalData, setArrRecordWithAdditionalData] = useState(null);
  const [numberOfInRecordWithAdditionalData, setNumberOfInRecordWithAdditionalData] = useState(0);

  const navigate = useNavigate();
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const fetchDataForTaggingPage = useCallback(async () => {
    try {
      setShowLoader(true);
      const accessToken = await getAccessTokenSilently();
      const response = await axios.post(
        '/web-app/fetch-internal-tagging-data',
        {},
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      const { arrUntaggedRecordWithAdditionalData, numberOfUntaggedInRecordWithAdditionalData } =
        response.data;

      setArrRecordWithAdditionalData(arrUntaggedRecordWithAdditionalData);
      setNumberOfInRecordWithAdditionalData(numberOfUntaggedInRecordWithAdditionalData);
      setShowLoader(false);
    } catch (error) {
      if (error.response.status === 401) {
        alert('Your session has expired. Please login again.');
      } else {
        alert('Error when retrieving data for internal tagging. Please notify admin.');
      }
      navigate('/login', { replace: true });
    }
  }, [getAccessTokenSilently, navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchDataForTaggingPage();
    } else if (!isAuthenticated && !isLoading) {
      navigate('/login', { replace: true });
    }
  }, [isAuthenticated, isLoading, fetchDataForTaggingPage, navigate]);

  const onClickCancel = () => {
    redirectToHomeFromTag();
  };

  const onClickCardMedia = (imageUrl) => {
    setIsDialogOpened(true);
    setImageUrlToExpand(imageUrl);
  };

  const onCloseDialog = () => {
    closeDialog();
  };

  const onClickCloseDialog = () => {
    closeDialog();
  };

  const closeDialog = () => {
    setIsDialogOpened(false);
    setImageUrlToExpand('');
  };

  const redirectToHomeFromTag = () => {
    navigate('/', { replace: true });
  };

  /**
   * Before sending the whole array of untagged records with additional data to the Backend for update, check if there is any input error. If there is, the
   * submission will be aborted and the page will be scrolled to the record card where the first input error is found. If there is no updated record,
   * the user will be redirected to the Home page.
   */
  const onClickSubmit = () => {
    let firstRecordIdWithInputError = 0;
    let hasUpdatedRecord = false;

    for (
      let recordWithAdditionalDataIndex = 0;
      recordWithAdditionalDataIndex < arrRecordWithAdditionalData.length;
      recordWithAdditionalDataIndex++
    ) {
      const recordWithAdditionalData = arrRecordWithAdditionalData[recordWithAdditionalDataIndex];
      if (recordWithAdditionalData.hasCountChanged) {
        if (recordWithAdditionalData.errorTextForCount) {
          firstRecordIdWithInputError = recordWithAdditionalData.recordId;
          break;
        }
      }
      hasUpdatedRecord = true;
    }
    if (firstRecordIdWithInputError) {
      scrollIntoViewForInvalidInput(firstRecordIdWithInputError);
    } else if (!hasUpdatedRecord) {
      redirectToHomeFromTag();
    } else {
      submitToTag();
    }
  };

  const submitToTag = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      await axios.post(
        '/web-app/update',
        {
          arrRecordWithAdditionalData,
          isTagging: true,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      redirectToHomeFromTag();
    } catch (error) {
      redirectToHomeFromTag();
    }
  };

  /**
   * Automatically scroll to the first record card with input error to assist the user in locating the first input error
   * @param {number} firstRecordIdWithInputError - Id of the first record card with input error
   * @returns {boolean} true or false - To indicate if the count input contains only numbers
   */
  const scrollIntoViewForInvalidInput = (firstRecordIdWithInputError) => {
    const element = document.querySelector(`[id="${firstRecordIdWithInputError}"]`);
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  /**
   * Check if count input only contains numbers. If yes, check if the number is in the range of 0 - 999.
   * @param {string} countInput - Text input under 'Count' section
   * @returns {boolean} true or false - To indicate if the count input contains only numbers
   */
  const checkIsCountInputValid = (countInput) => {
    const regEx = /^[0-9]+$/;
    if (regEx.test(countInput)) {
      const countInputInNumber = Number(countInput);
      if (countInputInNumber >= 0 && countInputInNumber <= 999) {
        return true;
      }
    }
    return false;
  };

  /**
   * Update the count in the inventory record with additional data object and add an attribute 'hasCountChanged' to indicate that the record needs
   * to be filtered for updated in the Backend. Validate the input value and include an error message for invalid input, which will be display
   * under 'Count' section, to inform the user.
   * @param {Object} event
   * @param {number} recordId - Id of record where there is a change in its key
   */
  const onChangeTextFieldForCount = (event, recordId) => {
    const { value } = event.target;
    const arrUpdatedRecordWithAdditionalData = arrRecordWithAdditionalData.map(
      (recordWithAdditionalData) => {
        if (recordWithAdditionalData.recordId === recordId) {
          const updatedRecord = { ...recordWithAdditionalData };
          updatedRecord.hasCountChanged = true;
          if (checkIsCountInputValid(value)) {
            updatedRecord.count = Number(value);
            delete updatedRecord.errorTextForCount;
          } else {
            updatedRecord.count = value;
            if (!value) {
              updatedRecord.errorTextForCount = 'Count should not be empty';
            } else {
              updatedRecord.errorTextForCount = 'Count should be a number that is between 0 to 999';
            }
          }
          return updatedRecord;
        }
        return recordWithAdditionalData;
      }
    );
    setArrRecordWithAdditionalData(arrUpdatedRecordWithAdditionalData);
  };

  return (
    <>
      {showLoader || !arrRecordWithAdditionalData ? (
        <Loader />
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{ paddingTop: 4, paddingBottom: 8 }}
        >
          <Grid item align="left" sx={{ width: '100%', paddingLeft: '41px' }}>
            <StyledReturnButton onClick={onClickCancel}>
              <ArrowCircleLeftIcon sx={{ fontSize: '300%' }} />
              <Typography
                sx={{ position: 'relative', top: '3px', fontSize: '26px', paddingLeft: 1 }}
              >
                Return To Home
              </Typography>
            </StyledReturnButton>
          </Grid>
          <Grid item align="center" sx={{ padding: '24px 8px 8px 8px' }}>
            <Grid
              container
              spacing={2}
              sx={{
                width: '90%',
                padding: 1,
              }}
            >
              <Grid item xs={12}>
                <Typography align="left" sx={{ fontSize: 20, color: '#969D9E' }}>
                  {`Found ${numberOfInRecordWithAdditionalData} Untagged Record(s)`}
                </Typography>
              </Grid>
              {arrRecordWithAdditionalData.map((recordWithAdditionalData) => {
                return (
                  recordWithAdditionalData.isGoingIntoStorage && (
                    <Grid
                      item
                      key={recordWithAdditionalData.recordId}
                      id={recordWithAdditionalData.recordId}
                      xs={6}
                      sx={{ display: 'block', padding: 1, borderRadius: 3 }}
                    >
                      <Card elevation={0} sx={{ borderRadius: 3 }}>
                        <Box
                          sx={{
                            overflow: 'auto',
                            display: 'flex',
                            border: 3,
                            borderColor: '#1D4486',
                            borderRadius: 3,
                          }}
                        >
                          <CardActionArea
                            sx={{ minWidth: '50%', height: 'auto', align: 'center' }}
                            onClick={() => onClickCardMedia(recordWithAdditionalData.imageUrl)}
                          >
                            <CardMedia
                              component="img"
                              image={recordWithAdditionalData.imageUrl}
                              alt="CPCS"
                            />
                          </CardActionArea>

                          <CardContent sx={{ width: '100%', paddingTop: 2 }}>
                            <Grid
                              sx={{
                                display: 'block',
                                alignItems: 'flex-start',
                                paddingBottom: 3,
                                columnGap: 4,
                              }}
                            >
                              <Grid sx={{ display: 'flex', padding: 0.5 }}>
                                <Typography sx={{ fontSize: 16, textAlign: 'left' }}>
                                  Tray Serial: {recordWithAdditionalData.traySerial}
                                </Typography>
                              </Grid>
                              <Grid sx={{ display: 'flex', padding: 0.5 }}>
                                <Typography sx={{ fontSize: 16, textAlign: 'left' }}>
                                  SKU: {recordWithAdditionalData.productCode}
                                </Typography>
                              </Grid>
                              <Grid sx={{ display: 'flex', padding: 0.5 }}>
                                <Typography
                                  sx={{
                                    fontSize: 16,
                                    textAlign: 'left',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '1',
                                    WebkitBoxOrient: 'vertical',
                                  }}
                                >
                                  Item: {recordWithAdditionalData.foodItemName}
                                </Typography>
                              </Grid>
                              <Grid sx={{ display: 'flex', padding: 0.5 }}>
                                <Typography sx={{ fontSize: 16, textAlign: 'left' }}>
                                  Weight: {recordWithAdditionalData.weight.toFixed(2)} kg
                                </Typography>
                              </Grid>
                              <Grid sx={{ display: 'flex', padding: 0.5 }}>
                                <Typography sx={{ fontSize: 16, textAlign: 'left' }}>
                                  Time Taken: {recordWithAdditionalData.time}
                                </Typography>
                              </Grid>
                              <Grid sx={{ display: 'flex', padding: 0.5 }}>
                                <Typography sx={{ fontSize: 16, textAlign: 'left' }}>
                                  Colo Room: {recordWithAdditionalData.locationName}
                                </Typography>
                              </Grid>
                              <Grid sx={{ display: 'flex', padding: 0.5 }}>
                                <Typography sx={{ fontSize: 16, textAlign: 'left' }}>
                                  In/Out:{' '}
                                  {recordWithAdditionalData.isGoingIntoStorage ? 'In' : 'Out'}
                                </Typography>
                              </Grid>
                              <Grid sx={{ display: 'flex', padding: 0.5 }}>
                                <Typography sx={{ fontSize: 16, textAlign: 'left' }}>
                                  Last Edit Time: {recordWithAdditionalData.lastEditTime}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid sx={{ display: 'flex' }}>
                              <TextField
                                label="Count"
                                id="count"
                                value={recordWithAdditionalData.count}
                                sx={{ margin: 1, width: '10ch' }}
                                InputProps={{ style: { fontSize: 16 } }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                helperText={recordWithAdditionalData.errorTextForCount}
                                error={recordWithAdditionalData.errorTextForCount ? true : false}
                                onChange={(event) =>
                                  onChangeTextFieldForCount(
                                    event,
                                    recordWithAdditionalData.recordId
                                  )
                                }
                                variant="standard"
                                required
                              />
                            </Grid>
                          </CardContent>
                        </Box>
                      </Card>
                    </Grid>
                  )
                );
              })}
            </Grid>
            <Stack direction="row" justifyContent="center" padding="28px">
              <StyledSubmitButton variant="contained" onClick={onClickSubmit}>
                Submit
              </StyledSubmitButton>
            </Stack>
          </Grid>

          {isDialogOpened && (
            <Dialog
              aria-label="max-width-image-dialog"
              fullWidth
              maxWidth="xl"
              onClose={onCloseDialog}
              open={isDialogOpened}
            >
              <DialogContent
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingTop: 6,
                }}
              >
                <img width="100%" height="100%" src={imageUrlToExpand} alt="CPCS"></img>
                <IconButton
                  sx={{ position: 'absolute', right: 8, top: 8 }}
                  onClick={onClickCloseDialog}
                >
                  <CloseIcon />
                </IconButton>
              </DialogContent>
            </Dialog>
          )}
        </Grid>
      )}
    </>
  );
};

export default Tag;
