import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const StyledFilterButton = styled(Button)({
  fontSize: '16px',
  fontWeight: 'bold',
  backgroundColor: '#63C6CA',
  borderRadius: '24px',
  width: '250px',
  '&:hover': {
    backgroundColor: '#1D4486',
  },
});

const StyledRemoveButton = styled(Button)({
  fontSize: '16px',
  fontWeight: 'bold',
  backgroundColor: '#EDA6A3',
  borderRadius: '24px',
  width: '250px',
  '&:hover': {
    backgroundColor: '#E3705D',
    borderRadius: '24px',
  },
});

const FilterModal = (props) => {
  const {
    arrAllOption,
    arrPreviousSelectedOption,
    filterBy,
    nameOfFilterBy,
    shouldOpen,
    close,
    onClickRemove,
    onClickSubmit,
  } = props;

  const [arrFilteredOption, setArrFilteredOption] = useState([]);
  const [arrSelectedOption, setArrSelectedOption] = useState([]);
  useEffect(() => {
    setArrFilteredOption(arrPreviousSelectedOption);
    setArrSelectedOption(arrPreviousSelectedOption);
  }, [filterBy, arrAllOption, arrPreviousSelectedOption]);

  const onClose = () => {
    close();
  };

  const onClickClose = () => {
    close();
  };

  /**
   * Check if a string is a substring of the other string
   * @param {string} stringA - String to check if it is a substring of the other string
   * @param {string} stringB - String that may contain the other string as part of it
   * @returns {boolean} True or false
   */
  const checkIsSubString = (stringA, stringB) => {
    const lengthOfStringA = stringA.length;
    const lengthOfStringB = stringB.length;
    const stringAInLowerCase = stringA.toLowerCase();
    const stringBInLowerCase = stringB.toLowerCase();
    for (let stringBIndex = 0; stringBIndex <= lengthOfStringB - lengthOfStringA; stringBIndex++) {
      let stringAIndex = 0;
      for (stringAIndex = 0; stringAIndex < lengthOfStringA; stringAIndex++) {
        if (stringBInLowerCase[stringAIndex + stringBIndex] !== stringAInLowerCase[stringAIndex]) {
          break;
        }
      }
      if (stringAIndex === lengthOfStringA) {
        return true;
      }
    }
    return false;
  };

  const onChangeTextField = (event) => {
    const { value } = event.target;
    const arrNewFilteredOption = [];
    if (!value.trim()) {
      setArrFilteredOption([]);
    } else {
      arrAllOption.forEach((option) => {
        if (checkIsSubString(value, option.label)) {
          arrNewFilteredOption.push(option);
        }
      });
      setArrFilteredOption(arrNewFilteredOption);
    }
  };

  const checkIfOptionIsInArray = (arrOption, optionToCheck) => {
    const matchedOption = arrOption.find((option) => option.value === optionToCheck.value);
    if (!matchedOption) {
      return false;
    } else {
      return true;
    }
  };

  const selectOption = (filteredOption) => {
    const arrNewSelectedOption = arrSelectedOption.map((selectedOption) => selectedOption);
    arrNewSelectedOption.push(filteredOption);
    setArrSelectedOption(arrNewSelectedOption);
  };

  const deselectOption = (filteredOption) => {
    const arrNewSelectedOption = arrSelectedOption.filter(
      (selectedOption) => selectedOption.value !== filteredOption.value
    );
    setArrSelectedOption(arrNewSelectedOption);
  };

  const onClickSelectOrDeselect = (filteredOption) => {
    if (!checkIfOptionIsInArray(arrSelectedOption, filteredOption)) {
      selectOption(filteredOption);
    } else {
      deselectOption(filteredOption);
    }
  };

  return (
    <Dialog
      aria-label="max-width-filter-modal-dialog"
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={shouldOpen}
    >
      <DialogTitle>
        <Typography
          sx={{ fontSize: '18px', paddingTop: 1, textAlign: 'center' }}
        >{`FILTER BY ${nameOfFilterBy}`}</Typography>
        <IconButton onClick={onClickClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <TextField
          fullWidth
          id="filter-modal"
          variant="outlined"
          placeholder={`Search for ${nameOfFilterBy}`}
          InputProps={{
            textSize: '30px',
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={onChangeTextField}
        />
        {arrFilteredOption.length !== 0 && (
          <Stack
            direction="column"
            alignItems="stretch"
            divider={
              <Divider orientation="horizontal" sx={{ borderBottom: 1, color: '#9A9A9C' }} />
            }
            sx={{ paddingTop: 2, borderBottom: 1, borderBottomColor: '#9A9A9C' }}
          >
            <Typography
              sx={{ paddingBottom: 3, color: '#9A9A9C' }}
            >{`Found ${arrFilteredOption.length} result(s)`}</Typography>
            {arrFilteredOption.map((filteredOption) =>
              checkIfOptionIsInArray(arrSelectedOption, filteredOption) ? (
                <Box
                  key={filteredOption.value}
                  onClick={() => onClickSelectOrDeselect(filteredOption)}
                  sx={{
                    padding: '10px 10px',
                    margin: '6px 4px',
                    backgroundColor: '#EFF2F7',
                    boxShadow: '3px 3px 10px lightgrey',
                    borderRadius: '5px',
                  }}
                >
                  <Typography>{filteredOption.label}</Typography>
                </Box>
              ) : (
                <Box
                  key={filteredOption.value}
                  onClick={() => onClickSelectOrDeselect(filteredOption)}
                  sx={{
                    padding: '10px 10px',
                    margin: '6px 4px',
                    backgroundColor: '#FFFFFF',
                  }}
                >
                  <Typography>{filteredOption.label}</Typography>
                </Box>
              )
            )}
          </Stack>
        )}

        <Stack direction="row" justifyContent="space-between" sx={{ paddingTop: 4 }}>
          <StyledRemoveButton
            variant="contained"
            color="error"
            sx={{ columnGap: 6 }}
            onClick={onClickRemove}
          >
            Remove All Filter
          </StyledRemoveButton>
          <StyledFilterButton
            variant="contained"
            disabled={arrFilteredOption.length > 0 && arrSelectedOption.length !== 0 ? false : true}
            onClick={() => onClickSubmit(arrSelectedOption, filterBy)}
          >
            Filter
          </StyledFilterButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default FilterModal;
