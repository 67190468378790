import Box from '@mui/material/Box';
import loaderGif from './Loader_GIF/Loader_Lumitics.gif';

const Loader = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
      <img src={loaderGif} alt="Loading..." />
    </Box>
  );
};

export default Loader;
