import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import backgroundLumiticsLogin from './Loader_GIF/Background_Lumitics_Login.jpg';

const StyledButton = styled(Button)({
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#1C406E',
  backgroundColor: '#63C6CA',
  border: '3px solid',
  borderRadius: '24px',
  borderColor: '#63C6CA',
  width: '20%',
  height: '10%',
  '&:hover': {
    color: '#1D4486',
    backgroundColor: 'transparent',
    border: '3px solid',
    borderRadius: '24px',
    borderColor: '#1D4486',
  },
});

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  const onClickLogin = () => {
    loginWithRedirect();
  };

  return (
    <Grid
      container
      // direction="column"
      // alignItems="center"
      // columnGap={2}
      // paddingTop={10}
      width="100%"
      height="100%"
      // sx={{ height: '100%'}}
    >
      <Grid
        item
        xs={12}
        lg={12}
        sx={{ display: 'flex', height: '40vh', justifyContent: 'center', paddingTop: 1.7 }}
        backgroundColor="#102547"
      >
        <img src={backgroundLumiticsLogin} alt="Lumitics" />
      </Grid>
      <Grid
        item
        xs={12}
        lg={12}
        sx={{
          display: 'flex',
          height: '60vh',
          justifyContent: 'center',
          paddingTop: 12,
          background:
            'linear-gradient(180deg, #102547 0%, #152A4F 25%, #17305C 50%, #1C396A 75%, #214079 100%)',
        }}
      >
        <StyledButton variant="outlined" onClick={onClickLogin}>
          Click here to Login
        </StyledButton>
      </Grid>
    </Grid>
  );
};

export default Login;
